import Axios from "axios";

export const epsilonFormData = {
  Address: {
    PostalCode: "",
    Country: "US",
    ChannelCode: "DM",
    Location: "H",
    Status: "A",
    IsPreferred: "Y"
  },
  BirthDate: "",
  FirstName: "",
  MiddleInit: "",
  LastName: "",
  Emails: [
    {
      EmailAddr: "",
      ChannelCode: "EM",
      Location: "H",
      DeliveryStatus: "A",
      Status: "A",
      IsPreferred: "Y"
    }
  ]
};

export const AddProfile = async ({ formData, overLayPopUp = false }: any) => {
  const newEpsilonFormData = {
    ...epsilonFormData,
    FirstName: formData["FirstName"].value,
    LastName: formData["LastName"].value,
    BirthDate: formData["BirthDate"].value,
    Address: {
      ...epsilonFormData.Address,
      PostalCode: formData["PostalCode"].value
    },
    Emails: [
      {
        ...epsilonFormData.Emails[0],
        EmailAddr: formData["EmailAddr"].value
      }
    ]
  };
  try {
    const response = await Axios.post("https://www.tresemme.com/us/en/api/epsilon-add-profile", {
      formData: newEpsilonFormData,
      overLayPopUp
    });
    const { data: addProfileResponse } = response;
    return addProfileResponse;
  } catch (err) {
    throw Error(err);
  }
};

export const AddPromotion = async ({ formData, overLayPopUp = false }: any) => {
  try {
    const response = await Axios.post("https://www.tresemme.com/us/en/api/epsilon-add-promotion", { formData, overLayPopUp });
    const { data: addPromotionResponse } = response;
    return addPromotionResponse;
  } catch (err) {
    throw Error(err);
  }
};
