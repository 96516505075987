import React, { FunctionComponent, useState } from "react";
import { Form } from "react-bootstrap";
import Input from "../Input";
import Button from "../Button";
import { emailValidation, zipCodeValidation, nameValidation, dateValidation } from "../../utils/helper";
import "./styles.scss";
import { LoaderIcon } from "../../images/icons/loader";
import NewsletterResponse from "../NewsletterResponse";
import { AddProfile, AddPromotion } from "../../utils/epsilon";
import { event58 } from "../../analytics/event58";
import { event50 } from "../../analytics/event50";

interface NewsletterFormInterface {
  labelRequired?: boolean;
  signUpForm?: boolean;
  formStatus: string;
  setFormStatus: (status: string) => void;
  overLayPopUp?: boolean;
}

const NewsletterFormElement: FunctionComponent<NewsletterFormInterface> = ({
  labelRequired = true,
  signUpForm = false,
  formStatus,
  setFormStatus,
  children,
  overLayPopUp
}) => {
  const initNameErrorLabel = "Please use letters or punctuation only for Name";
  const initEmailErrorLabel = "Please enter a valid email address";
  const initZipCodeErrorLabel = "Please enter a valid Zip Code.";
  const initBirthErrorLabel = "Please enter a valid Date-of-Birth";
  const initFormData = {
    EmailAddr: {
      isValid: true,
      value: "",
      errorLabel: initEmailErrorLabel
    },
    FirstName: {
      isValid: true,
      value: "",
      errorLabel: initNameErrorLabel
    },
    LastName: {
      isValid: true,
      value: "",
      errorLabel: initNameErrorLabel
    },
    PostalCode: {
      isValid: true,
      value: "",
      errorLabel: initZipCodeErrorLabel
    },
    BirthDate: {
      isValid: true,
      value: "",
      errorLabel: initBirthErrorLabel
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initFormData);

  const { EmailAddr, LastName, FirstName, PostalCode, BirthDate } = formData;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData: any = { ...formData };
    const value = e.target.value;
    const type = e.target.id;
    const isValid = handleValidationByType(type, value);
    const errorLabel = getDefaultErrorLabel(type);
    newFormData[e.target.id] = {
      ...newFormData[e.target.id],
      isValid: isValid,
      value: value,
      errorLabel
    };
    setFormData({
      ...newFormData
    });
  };

  const getDefaultErrorLabel = (type: string) => {
    let value = "";
    switch (type) {
      case "EmailAddr":
        value = initEmailErrorLabel;
        break;
      case "LastName":
      case "FirstName":
        value = initNameErrorLabel;
        break;
      case "PostalCode":
        value = initZipCodeErrorLabel;
        break;
      case "BirthDate":
        value = initBirthErrorLabel;
        break;
      default:
        break;
    }
    return value;
  };

  const handleAllValidation = () => {
    const { EmailAddr, LastName, FirstName, PostalCode, BirthDate } = formData;
    const newFormData = {
      ...formData,
      EmailAddr: {
        ...EmailAddr,
        isValid: emailValidation(EmailAddr.value)
      },
      LastName: {
        ...LastName,
        isValid: nameValidation(LastName.value)
      },
      FirstName: {
        ...FirstName,
        isValid: nameValidation(FirstName.value)
      },
      PostalCode: {
        ...PostalCode,
        isValid: zipCodeValidation(PostalCode.value)
      },
      BirthDate: {
        ...BirthDate,
        isValid: dateValidation(BirthDate.value)
      }
    };
    return newFormData;
  };

  const handleValidationByType = (type: string, value: string) => {
    let isValid = false;
    switch (type) {
      case "EmailAddr":
        isValid = emailValidation(value);
        break;
      case "LastName":
      case "FirstName":
        isValid = nameValidation(value);
        break;
      case "PostalCode":
        isValid = zipCodeValidation(value);
        break;
      case "BirthDate":
        isValid = dateValidation(value);
        break;
      default:
        break;
    }
    return isValid;
  };

  const checkValidation = () => {
    const newFormData = handleAllValidation();
    const { EmailAddr, FirstName, LastName, PostalCode, BirthDate } = newFormData;
    if (EmailAddr.isValid && FirstName.isValid && LastName.isValid && PostalCode.isValid && BirthDate.isValid) {
      return true;
    } else {
      setFormData(newFormData);
      return false;
    }
  };

  const hanldeFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const isFormValid = checkValidation();
    if (!isFormValid) {
      setIsLoading(false);
      return;
    }
    try {
      const addProfileResponse = await AddProfile({ formData, overLayPopUp });
      if (addProfileResponse["Type"] !== "PROCESSED") {
        handleErrorResponse(addProfileResponse);
      } else {
        await AddPromotion({
          formData: {
            ProfileID: addProfileResponse["NewProfileID"],
            EmailAddress: EmailAddr.value
          },
          overLayPopUp
        });
        setFormStatus("success");
        event58();
        event50();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setFormStatus("error");
      console.log(err);
    }
  };

  const handleErrorResponse = (addProfileResponse: any) => {
    // check the api response and show the appropriate message
    const errorCode = addProfileResponse["ErrorDesc"]["Number"];
    const errorLabel = addProfileResponse["ErrorDesc"]["Value"];
    switch (errorCode) {
      case "12014":
        setFormData({
          ...formData,
          EmailAddr: {
            ...EmailAddr,
            errorLabel,
            isValid: false
          }
        });
        break;
      case "200002":
        setFormData({
          ...formData,
          BirthDate: {
            ...BirthDate,
            errorLabel,
            isValid: false
          }
        });
        break;
      default:
        break;
    }
    setFormStatus("idle");
  };

  const handleFormReset = () => {
    setIsLoading(false);
    setFormStatus("idle");
    setFormData(initFormData);
  };

  return (
    <>
      {formStatus === "idle" && (
        <Form className="form">
          <Form.Group className="d-flex justify-content-between">
            <Form.Group className={`${!FirstName["isValid"] || !LastName["isValid"] ? "pb-4" : "pb-3"} mr-3 flex-grow-1 w-100 d-flex flex-column justify-content-end`}>
              <Input
                type="text"
                className="w-100"
                placeholder="First Name"
                isRequired={true}
                onChange={onChange}
                value={FirstName["value"]}
                isValid={FirstName["isValid"]}
                id="FirstName"
                errorLabel={labelRequired ? FirstName["errorLabel"] : ""}
                pattern={"[a-zA-Z\\s]+"}
                data-analytics-event57
              />
            </Form.Group>
            <Form.Group className={`${!LastName["isValid"] || !FirstName["isValid"] ? "pb-4" : "pb-3"} flex-grow-1 d-flex w-100 flex-column justify-content-end`}>
              <Input
                type="text"
                className="w-100"
                placeholder="Last Name"
                isRequired={true}
                onChange={onChange}
                value={LastName["value"]}
                isValid={LastName["isValid"]}
                id="LastName"
                errorLabel={labelRequired ? LastName["errorLabel"] : ""}
                pattern={"[a-zA-Z\\s]+"}
                data-analytics-event57
              />
            </Form.Group>
          </Form.Group>
          <Form.Group className={`${!EmailAddr["isValid"] ? "pb-4" : "pb-3"} w-100`}>
            <Input
              type="email"
              className="w-100"
              placeholder="Your email"
              isRequired={true}
              onChange={onChange}
              value={EmailAddr["value"]}
              isValid={EmailAddr["isValid"]}
              id="EmailAddr"
              errorLabel={labelRequired ? EmailAddr["errorLabel"] : ""}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              data-analytics-event57
            />
          </Form.Group>
          <Form.Group className="d-flex d-flex flex-row align-items-end">
            <Form.Group className={`${!BirthDate["isValid"] || !PostalCode["isValid"] ? "pb-4" : "pb-3"} mr-3 w-100 flex-grow-1`}>
              <Input
                type="date"
                className="w-100"
                placeholder="Date of birth"
                isRequired={true}
                onChange={onChange}
                value={BirthDate["value"]}
                min="1900-01-01"
                id="BirthDate"
                isValid={BirthDate.isValid}
                errorLabel={labelRequired ? BirthDate["errorLabel"] : ""}
                data-analytics-event57
              />
            </Form.Group>
            <Form.Group className={`${!PostalCode["isValid"] || !BirthDate["isValid"] ? "pb-4" : "pb-3"} w-100 flex-grow-1`}>
              <Input
                type="text"
                className="w-100"
                placeholder="ZIP code"
                isRequired={true}
                onChange={onChange}
                value={PostalCode["value"]}
                isValid={PostalCode["isValid"]}
                id="PostalCode"
                maxLength={10}
                pattern="^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$"
                errorLabel={labelRequired ? PostalCode["errorLabel"] : ""}
                data-analytics-event57
              />
            </Form.Group>
          </Form.Group>
          {children}
          <Button
            variant="golden"
            type="submit"
            className={`w-100 text-uppercase py-3 font-weight-bolder ${isLoading ? "disabled" : ""}`}
            onClick={hanldeFormSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Loading
                <LoaderIcon />
              </>
            ) : signUpForm ? (
              "SIGN UP"
            ) : (
              "SUBSCRIBE"
            )}
          </Button>
        </Form>
      )}
      {formStatus === "success" && (
        <NewsletterResponse
          title="Thank you"
          description="Thank you for your interest in TRESemmé products! Your message has been transmitted successfully. We will be in touch shortly- please be sure to adjust your SPAM filter to ensure you receive our response."
        />
      )}
      {formStatus === "error" && (
        <NewsletterResponse
          title="Failure"
          description="There was an error trying to send your message. Please try again"
          handleFormReset={handleFormReset}
        />
      )}
    </>
  );
};

export default NewsletterFormElement;
