import React, { FunctionComponent } from "react";
import "./styles.scss";

export interface InputInterface extends React.ComponentPropsWithoutRef<"input"> {
  type: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired: boolean;
  validation?: any;
  errorLabel?: string;
  className?: string;
  onBlur?: any;
  isValid?: boolean;
}

const Input: FunctionComponent<InputInterface> = (props: InputInterface) => {
  const {
    placeholder,
    value,
    onChange,
    type,
    isRequired,
    errorLabel,
    isValid,
    validation,
    onBlur,
    className,
    ...rest
  } = props;

  return (
    <>
      <label>
        {placeholder}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={isRequired}
          className={`${className} ${!isValid ? "invalid" : ""}`}
          {...rest}
        />
      </label>
      {!isValid && errorLabel && <span className="invalid-label font-weight-light"> {errorLabel} </span>}
    </>
  );
};

export default Input;
