import React, { FunctionComponent } from "react";
import { Link } from "../Link";
import "./styles.scss";

interface NewsletterResponseInterface {
  title: string;
  description: string;
  handleFormReset?: () => void;
  buttonVariant?: string;
}

const NewsletterResponse: FunctionComponent<NewsletterResponseInterface> = props => {
  const { title, description, handleFormReset, buttonVariant = "secondary" } = props;
  const handleFormClick = (e: React.MouseEvent<HTMLElement>) => {
    if (handleFormReset) {
      e.preventDefault();
      handleFormReset();
      return;
    }
  };
  return (
    <div className="newsletterResponse my-4 my-md-0">
      <h2 className="newsletterResponse__title text-uppercase my-4">{title}</h2>
      <p className="newsletterReponse__description mb-4 mb-md-5">{description}</p>
      <Link
        className="newsletterResponse__link text-uppercase px-4 my-4"
        asButton={buttonVariant}
        to={handleFormReset ? "#" : "/"}
        onClick={handleFormClick}
      >
        {handleFormReset ? "Sign me up again!" : "Back To Home"}
      </Link>
    </div>
  );
};

export default NewsletterResponse;
