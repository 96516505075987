import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

export const event50 = () => {
  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.Acquisition,
      eventLabel: "Successful Opt In",
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: "Lead"
  };
  pushEvent(EventData);
  logEvent("Successfull Opt in ", EventData);
};
